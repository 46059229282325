.boat-booking-modal {
  padding: 16px;
  border: 1px black solid;
  border-radius: 5px;
  width: 100%;

  &__modal-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .modal-header-details-1 {
    flex: 2;

    display: flex;
    flex-direction: row;
    align-items: center;

    .blocked-container {
      background-color: black;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .Blocked_text {
      color: white;
      font-weight: bold;
      font-size: 18px;
      font-family: "PublicSans-Medium";
    }
  }

  .SE__date {
    flex: 1;
  }

  &__modal-footer {
    display: flex;
    flex-direction: row;
  }

  &__notes-container {
    display: flex;
    flex-direction: column;
    border: 1px #cac8c8 solid;
    border-radius: 6px;
    margin-right: 16px;
    padding: 12px;

    flex: 1;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: end;
    align-items: end;
    gap: 10px;
  }
}

p {
  // all: unset;
  margin-bottom: auto;
}

.user {
  display: flex;
  flex-direction: column;
}
.boatName {
  vertical-align: -webkit-baseline-middle;
}
.boat_data {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.box1 {
  border: 1px #cac8c8 solid;
  border-radius: 6px;

  flex: 1;

  padding: 12px;
  margin-right: 16px;
}

.box2 {
  border: 1px #cac8c8 solid;
  border-radius: 6px;

  flex: 1;
  padding: 12px;
}

.data_text {
  color: #4f4f4f;
  font-size: 12px;
  font-family: "PublicSans-Medium";

  &--heading {
    font-family: "PublicSans-Bold";
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.b1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &__icon {
    height: 14px;
    width: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.b2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 8px;
}
.text_Input {
  width: 65px;
  margin-left: 5px;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px #c1c1c1 solid;
  ::placeholder {
    color: #c1c1c1;
    font-family: "PublicSans-Medium";
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
  }

  &.editable {
    border: 1px #000000 solid;
  }
}
.d2 {
  margin-right: 15px;
}

.text1 {
  color: black;
  margin-top: 8px;
  font-family: "PublicSans-Medium";
  font-size: 12px;
}
.main_div2 {
  border: 1px rgb(178, 176, 176) solid;
  margin-top: 4px;
}
.text2 {
  font-family: "PublicSans-Bold";
}

.boatNameText {
  color: #149cd4;
  font-weight: bold;
  border: 1px #149cd4 solid;
  vertical-align: -webkit-baseline-middle;
  text-align: center;
  padding: 3px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "PublicSans-Medium";
}
.date_text {
  color: #000000;
  font-family: "PublicSans-Medium";
  font-size: 0.7em;
}
.user_text {
  color: #2e2e2e;
  font-size: 10px;
  font-family: "PublicSans-Medium";
}

.icon {
  height: 14px;
  width: 14px;
  margin-left: 10px;
}
