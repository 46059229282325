@import "crm";
@import "boatBookingModal";

// Mantine React Table Styles

body {
  .mrt-table-head-cell-content {
    justify-content: center !important;
  }

  [class^="MRT_TopToolbar-module_actions-container"] {
    position: relative;

    justify-content: flex-start;
  }
  [class^="MRT_BottomToolbar-module_paginator-container"] {
    // background-color: antiquewhite;
    width: 100%;
    justify-content: flex-start;

    // justify-content: center !important;
  }
  // Center align all cells
  [class*="MRT_TableBodyCell-module_root"] {
    --mrt-cell-align: center;
    text-align: center;
  }
  [class*="react-international-phone-country-selector"] {
    border-top-left-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
  }
}
